import {contractAddresses, LoyaltyToken, Oracle, Pool, PoolLens, Swych} from "../constants/contracts";
import {availableTokenList, availableTokens, feeTokenList, wrappedNativeToken} from "../constants/tokens";
import {toBigInt, ZeroAddress} from "ethers";

export const fetchTotalPoolAmount = async () => {
    return Pool.getPoolValue(true);
};

export const fetchTotalProtocolRevenue = async () => {
    // const poolValue = await Pool.virtualPoolValue();
    // const totalAddedLiquidity = await Pool.getTotalAddedLiquidityUsd();
    return await Promise.all(feeTokenList.map(async (token) => {
        const fee = await Pool.feeReserves(token);
        const tokenPrice = await Oracle.getPrice(token, true);
        return fee * tokenPrice;
    })).then(
        (values) => values.reduce((a, b) => a + b, 0n)
    );
};

export const fetchUserAccruedPoints = async (address) => {
    return LoyaltyToken.balanceOf(address);
};

export const fetchSwychBalance = async (address) => {
    return Swych.balanceOf(address);
};

export const fetchOpenPositions = async (address) => {
    const {collateralTokens, indexTokens, isLong} = generatePositionQuery();
    return {
        positionData: await PoolLens.getPositions(contractAddresses.Pool, address, collateralTokens, indexTokens, isLong),
        positionQuery: {collateralTokens, indexTokens, isLong},
    };
};

export const getAllLiveTokens = async (livePrices, fundingRateData) => {
    return PoolLens.getPoolTokenInfo(contractAddresses.Pool, availableTokenList).then((data) => {
        return processLiveTokenData(data, livePrices, fundingRateData);
    });
};

export const getAllLiveTokenFundingRates = async () => {
    return PoolLens.getFundingRates(contractAddresses.Pool, availableTokenList);
}

const generatePositionQuery = () => {
    const tokens = Object.values(availableTokens);
    const nativeTokenAddress = wrappedNativeToken.address;
    const numTokens = tokens.length;
    const collateralTokens = [];
    const indexTokens = [];
    const isLong = [];

    for (let i = 0; i < numTokens; i++) {
        const token = tokens[i];
        if (token.isStable) {
            continue;
        }
        if (token.isWrapped) {
            continue;
        }

        collateralTokens.push(covertNativeToWrapped(token, nativeTokenAddress));
        indexTokens.push(covertNativeToWrapped(token, nativeTokenAddress));
        isLong.push(true);
    }

    for (let i = 0; i < numTokens; i++) {
        const stableToken = tokens[i];
        if (!stableToken.isStable) {
            continue;
        }

        for (let j = 0; j < numTokens; j++) {
            const token = tokens[j];
            if (token.isStable) {
                continue;
            }
            if (token.isWrapped) {
                continue;
            }
            collateralTokens.push(stableToken.address);
            indexTokens.push(covertNativeToWrapped(token, nativeTokenAddress));
            isLong.push(false);
        }
    }

    return {collateralTokens, indexTokens, isLong};
};

const covertNativeToWrapped = (token, nativeTokenAddress) => {
    if (token.address === ZeroAddress) {
        return nativeTokenAddress;
    }

    return token.address;
};

const processLiveTokenData = (tokenData, livePrices, fundingRateData) => {
    const MIN_PRICE_PERCENTAGE = 3n;
    const MAX_PRICE_PERCENTAGE = 3n;
    const MIN_MAX_PRICE_PERCENTAGE_DIVISOR = 1000n;
    const DEFAULT_MAX_USDG_AMOUNT = 200n * 1000n * 1000n * (10n ** 18n);
    const vaultPropsLength = 15;
    const fundingRatePropsLength = 2;
    const infoTokens = {};
    const tokens = Object.values(availableTokens);
    const indexPrices = livePrices;
    const vaultTokenInfo = tokenData;
    const fundingRateInfo = fundingRateData;

    for (let i = 0; i < tokens.length; i++) {
        const token = JSON.parse(JSON.stringify(tokens[i]))

        if (tokenData && indexPrices) {
            token.poolAmount = toBigInt(vaultTokenInfo[i * vaultPropsLength]);
            token.reservedAmount = toBigInt(vaultTokenInfo[i * vaultPropsLength + 1]);
            token.availableAmount = token.poolAmount - token.reservedAmount;
            token.usdgAmount = toBigInt(vaultTokenInfo[i * vaultPropsLength + 2]);
            token.weight = toBigInt(vaultTokenInfo[i * vaultPropsLength + 4]);
            token.bufferAmount = toBigInt(vaultTokenInfo[i * vaultPropsLength + 5]);
            token.maxUsdgAmount = toBigInt(vaultTokenInfo[i * vaultPropsLength + 6]);
            token.globalShortSize = toBigInt(vaultTokenInfo[i * vaultPropsLength + 7]);
            token.maxGlobalShortSize = toBigInt(vaultTokenInfo[i * vaultPropsLength + 8]);
            token.maxGlobalLongSize = toBigInt(vaultTokenInfo[i * vaultPropsLength + 9]);
            token.minPrice = (toBigInt(indexPrices[token.address.toLowerCase()]) -
                    (toBigInt(indexPrices[token.address.toLowerCase()]) * MIN_PRICE_PERCENTAGE / MIN_MAX_PRICE_PERCENTAGE_DIVISOR))
                / (10n ** 18n);
            token.maxPrice = (toBigInt(indexPrices[token.address.toLowerCase()]) +
                    (toBigInt(indexPrices[token.address.toLowerCase()]) * MAX_PRICE_PERCENTAGE / MIN_MAX_PRICE_PERCENTAGE_DIVISOR))
                / (10n ** 18n);
            token.refPrice = toBigInt(indexPrices[token.address.toLowerCase()]);
            token.guaranteedUsd = toBigInt(vaultTokenInfo[i * vaultPropsLength + 12]);
            token.maxPrimaryPrice = toBigInt(vaultTokenInfo[i * vaultPropsLength + 13]);
            token.minPrimaryPrice = toBigInt(vaultTokenInfo[i * vaultPropsLength + 14]);
            token.contractMinPrice = token.minPrice;
            token.contractMaxPrice = token.maxPrice;
            token.availableUsd = token.isStable
                ? token.poolAmount * token.minPrice
                : token.availableAmount * token.minPrice;

            token.maxAvailableShort = 0n;
            token.hasMaxAvailableShort = false;

            if (token.maxGlobalShortSize > 0n) {
                token.hasMaxAvailableShort = true;

                if (token.maxGlobalShortSize > token.globalShortSize) {
                    token.maxAvailableShort = token.maxGlobalShortSize - token.globalShortSize;
                }
            } else {
                token.maxAvailableShort = token.availableAmount * token.minPrice;
            }

            if (token.maxUsdgAmount === 0n) {
                token.maxUsdgAmount = DEFAULT_MAX_USDG_AMOUNT;
            }

            token.maxAvailableLong = 0n;
            const maxGlobalLongSizeUsd = token.maxGlobalLongSize * token.maxPrice;
            token.hasMaxAvailableLong = false;
            if (token.maxGlobalLongSize > 0n) {
                token.hasMaxAvailableLong = true;

                if (maxGlobalLongSizeUsd > token.guaranteedUsd) {
                    const remainingLongSize = maxGlobalLongSizeUsd - token.guaranteedUsd;
                    token.maxAvailableLong = remainingLongSize < token.availableUsd ? remainingLongSize : token.availableUsd;
                }
            } else {
                token.maxAvailableLong = token.availableUsd;
            }

            token.maxLongCapacity =
                token.maxGlobalLongSize > 0n && maxGlobalLongSizeUsd < token.availableUsd + token.guaranteedUsd
                    ? maxGlobalLongSizeUsd
                    : token.availableUsd + token.guaranteedUsd;

            token.managedUsd = token.availableUsd + token.guaranteedUsd;
            token.managedAmount = token.managedUsd * (10n ** toBigInt(token.decimals)) / token.minPrice;
        }

        if (fundingRateInfo) {
            token.fundingRate = fundingRateInfo[i * fundingRatePropsLength];
            token.cumulativeFundingRate = fundingRateInfo[i * fundingRatePropsLength + 1];
        }

        if (infoTokens[token.address]) {
            token.balance = infoTokens[token.address].balance;
        }

        infoTokens[token.address] = token;
    }

    return infoTokens;
};

