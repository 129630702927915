import "./Dashboard.css";
import Layout from "../../components/Layout/Layout";
import {useEffect, useContext, useState} from "react";
import {Web3Context} from "../../contexts/Web3Context/Web3Context";
import {
    InfoBoxStats,
    InfoBoxBasic,
    InfoBoxSwychStatistics,
    InfoBoxTradingActivity
} from "../../components/InfoBox/InfoBox";
import {
    getAllUsers,
    getClosedPositions,
    getDailyStats,
    getOrders,
    getSwychDailyPrice,
    getSwychVolume, getWithdrawalFees
} from "../../data/data";
import {SIDE} from "../../constants/pdex";
import {
    beautifySwychDailyPrices,
    beautifyWeiNumber, calculate1WeekTimestamps, calculate24hTimestamps, calculateDailyStats,
    calculateGeneratedVolume, calculatePositionPnl,
    calculateTradingVolumeFromOrders, calculateWithdrawalAmountUsd, formatNumberWithCommas, getPositions
} from "../../utils/utils";
import {
    fetchOpenPositions,
    fetchSwychBalance,
    fetchTotalPoolAmount,
    fetchTotalProtocolRevenue,
    fetchUserAccruedPoints
} from "../../data/blockchain";
import {DataContext} from "../../contexts/DataContext/DataContext";
import {toBigInt} from "ethers";

function Dashboard() {
    const web3Details = useContext(Web3Context);
    const data = useContext(DataContext);
    // Handy constants.
    const VALUE_PLACEHOLDER = "...";
    const NUM_DAILY_PRICES_DAYS = 10;
    const UPDATE_INTERVAL = 1000 * 60;
    // Timers.
    const [updateTrigger, setUpdateTrigger] = useState(true);
    // All orders.
    const [orders, setOrders] = useState(null);
    const [ordersStr, setOrdersStr] = useState(VALUE_PLACEHOLDER);
    const [orders24h, setOrders24h] = useState(null);
    const [orders24hStr, setOrders24hStr] = useState(VALUE_PLACEHOLDER);
    // Trading volume.
    const [tradingVolume, setTradingVolume] = useState(null);
    const [tradingVolume24h, setTradingVolume24h] = useState(null);
    const [tradingVolumeStr, setTradingVolumeStr] = useState(VALUE_PLACEHOLDER);
    const [tradingVolume24hStr, setTradingVolume24hStr] = useState(VALUE_PLACEHOLDER);
    // AUM.
    const [aum, setAum] = useState(null);
    const [aumStr, setAumStr] = useState(VALUE_PLACEHOLDER);
    const [aum24h, setAum24h] = useState(null);
    const [aum24hStr, setAum24hStr] = useState(VALUE_PLACEHOLDER);
    // Protocol revenue.
    const [protocolRevenue, setProtocolRevenue] = useState(null);
    const [protocolRevenueStr, setProtocolRevenueStr] = useState(VALUE_PLACEHOLDER);
    const [protocolRevenue24h, setProtocolRevenue24h] = useState(null);
    const [protocolRevenue24hStr, setProtocolRevenue24hStr] = useState(VALUE_PLACEHOLDER);
    // Fees.
    const [withdrawalFees, setWithdrawalFees] = useState(null);
    const [withdrawalFees24h, setWithdrawalFees24h] = useState(null);
    const [withdrawalAmountUsd, setWithdrawalAmountUsd] = useState(0n);
    const [withdrawalAmountUsd24h, setWithdrawalAmountUsd24h] = useState(0n);
    // Swych prices.
    const [swychPrices, setSwychPrices] = useState([]);
    const [swychPriceData, setSwychPriceData] = useState([]);
    // Swych current price.
    const [swychCurrentPrice, setSwychCurrentPrice] = useState(null);
    const [swychCurrentPriceStr, setSwychCurrentPriceStr] = useState(VALUE_PLACEHOLDER);
    // Swych growth.
    const [swychGrowth, setSwychGrowth] = useState(null);
    const [swychGrowthStr, setSwychGrowthStr] = useState(VALUE_PLACEHOLDER);
    // Swych volume.
    const [swychVolume, setSwychVolume] = useState(null);
    const [swychVolumeStr, setSwychVolumeStr] = useState(VALUE_PLACEHOLDER);
    // User statistics.
    // Generated volume.
    const [generatedVolume, setGeneratedVolume] = useState(null);
    const [generatedVolumeStr, setGeneratedVolumeStr] = useState(VALUE_PLACEHOLDER);
    // Accrued points.
    const [accruedPoints, setAccruedPoints] = useState(null);
    const [accruedPointsStr, setAccruedPointsStr] = useState(VALUE_PLACEHOLDER);
    // Wins and losses.
    const [totalWins, setTotalWins] = useState(null);
    const [totalLosses, setTotalLosses] = useState(null);
    const [totalWinLossRatio, setTotalWinLossRatio] = useState(null);
    const [totalWinLossStr, setTotalWinLossStr] = useState(VALUE_PLACEHOLDER);
    // Total PnL.
    const [closedPositions, setClosedPositions] = useState(null);
    const [openPositions, setOpenPositions] = useState(null);
    const [closedPositionsPnl, setClosedPositionsPnl] = useState(null);
    const [openPositionsPnl, setOpenPositionsPnl] = useState(null);
    const [totalPnl, setTotalPnl] = useState(null);
    const [totalPnlStr, setTotalPnlStr] = useState(VALUE_PLACEHOLDER);
    const [openPositionsPnlStr, setOpenPositionsPnlStr] = useState(VALUE_PLACEHOLDER);
    const [openPositionsPnlGrowth, setOpenPositionsPnlGrowth] = useState(null);
    const [openPositionsPnlGrowthStr, setOpenPositionsPnlGrowthStr] = useState(VALUE_PLACEHOLDER);
    // Liquidity.
    const [totalLiquidityStr, setTotalLiquidityStr] = useState(VALUE_PLACEHOLDER);
    const [totalAvailableLiquidityStr, setTotalAvailableLiquidityStr] = useState(VALUE_PLACEHOLDER);
    const [liquidityStats, setLiquidityStats] = useState([]);
    const [totalLiquidityTooltipContent, setTotalLiquidityTooltipContent] = useState(null);
    // Chart data.
    const [pnlsData, setPnlsData] = useState([]);
    const [chartPnls, setChartPnls] = useState([]);
    const [pnlGrowth, setPnlGrowth] = useState(null);
    const [pnlGrowthStr, setPnlGrowthStr] = useState(VALUE_PLACEHOLDER);
    // Swych balance.
    const [swychBalance, setSwychBalance] = useState(null);
    const [swychBalanceStr, setSwychBalanceStr] = useState(VALUE_PLACEHOLDER);
    // Swych balance in USD.
    const [swychBalanceInUsd, setSwychBalanceInUsd] = useState(null);
    const [swychBalanceInUsdStr, setSwychBalanceInUsdStr] = useState(VALUE_PLACEHOLDER);
    // Users.
    const [users, setUsers] = useState(null);
    const [userCountTotal, setUserCountTotal] = useState(null);
    const [userCountNew, setUserCountNew] = useState(null);
    const [userCount24h, setUserCount24h] = useState(null);
    const [userStats, setUserStats] = useState([]);
    // Orders.
    const [longOrdersStr, setLongOrdersStr] = useState(VALUE_PLACEHOLDER);
    const [shortOrdersStr, setShortOrdersStr] = useState(VALUE_PLACEHOLDER);
    const [longOrdersVolumeStr, setLongOrdersVolumeStr] = useState(VALUE_PLACEHOLDER);
    const [shortOrdersVolumeStr, setShortOrdersVolumeStr] = useState(VALUE_PLACEHOLDER);
    const [orderStats, setOrderStats] = useState([]);
    // Daily stats.
    const [dailyStats, setDailyStats] = useState(null);
    // Time states.
    const [dataTimeRange24h, setDataTimeRange24h] = useState(null);
    const [dataTimeRange24hStr, setDataTimeRange24hStr] = useState(VALUE_PLACEHOLDER);
    // Loading states.
    const [ordersLoading, setOrdersLoading] = useState(true);
    const [orders24hLoading, setOrders24hLoading] = useState(true);
    const [tradingVolumeLoading, setTradingVolumeLoading] = useState(true);
    const [tradingVolume24hLoading, setTradingVolume24hLoading] = useState(true);
    const [aumLoading, setAumLoading] = useState(true);
    const [aum24hLoading, setAum24hLoading] = useState(true);
    const [protocolRevenueLoading, setProtocolRevenueLoading] = useState(true);
    const [protocolRevenue24hLoading, setProtocolRevenue24hLoading] = useState(true);
    const [accruedPointsLoading, setAccruedPointsLoading] = useState(true);
    const [generatedVolumeLoading, setGeneratedVolumeLoading] = useState(true);
    const [swychBalanceLoading, setSwychBalanceLoading] = useState(true);
    const [openPositionsPnlLoading, setOpenPositionsPnlLoading] = useState(true);
    const [userCountTotalLoading, setUserCountTotalLoading] = useState(true);
    const [userCountNewLoading, setUserCountNewLoading] = useState(true);
    const [userCount24hLoading, setUserCount24hLoading] = useState(true);
    const [longOrdersLoading, setLongOrdersLoading] = useState(true);
    const [shortOrdersLoading, setShortOrdersLoading] = useState(true);
    const [longOrdersVolumeLoading, setLongOrdersVolumeLoading] = useState(true);
    const [shortOrdersVolumeLoading, setShortOrdersVolumeLoading] = useState(true);
    const [totalLiquidityLoading, setTotalLiquidityLoading] = useState(true);
    const [totalWinLossLoading, setTotalWinLossLoading] = useState(true);

    // Helper functions.
    const refreshTradingVolume = () => {
        const volume = calculateTradingVolumeFromOrders(orders);
        setTradingVolume(volume.tradingVolume);
        setTradingVolume24h(volume.tradingVolume24h);
    };

    const refreshOrdersStatistics = () => {
        const longOrders = orders.filter(order => order.side === SIDE.LONG);
        const shortOrders = orders.filter(order => order.side === SIDE.SHORT);
        const longOrdersVolume = calculateTradingVolumeFromOrders(longOrders).tradingVolume;
        const shortOrdersVolume = calculateTradingVolumeFromOrders(shortOrders).tradingVolume;
        setLongOrdersStr(longOrders.length.toString());
        setShortOrdersStr(shortOrders.length.toString());
        setLongOrdersVolumeStr(`$${beautifyWeiNumber(longOrdersVolume, 30, 2, true)}`);
        setShortOrdersVolumeStr(`$${beautifyWeiNumber(shortOrdersVolume, 30, 2, true)}`);
    };

    const calculateUserGeneratedVolume = () => {
        setGeneratedVolume(calculateGeneratedVolume(orders, web3Details.address))
    };

    const fetchOrders = async () => {
        getOrders().then(orders => {
            setOrders(orders);
        });
    };

    const fetchAum = async () => {
        fetchTotalPoolAmount().then(aum => {
            setAum(aum);
        });
    };

    const fetchProtocolRevenue = async () => {
        fetchTotalProtocolRevenue().then(revenue => {
            setProtocolRevenue(revenue);
        });
    };

    const fetchAccruedPoints = async () => {
        fetchUserAccruedPoints(web3Details.address).then(points => {
            setAccruedPoints(points);
        });
    };

    const fetchSwychDailyPrices = async () => {
        getSwychDailyPrice(NUM_DAILY_PRICES_DAYS).then(prices => {
            setSwychPrices(beautifySwychDailyPrices(prices));
        });
    };

    const fetchUserSwychBalance = async () => {
        fetchSwychBalance(web3Details.address).then(balance => {
            setSwychBalance(balance);
        });
    };

    const fetchUserClosedPositions = async () => {
        getClosedPositions(web3Details.address).then(positions => {
            setClosedPositions(positions);
        });
    };

    const fetchUserOpenPositions = async () => {
        if (
            Object.keys(data.liveTokens).length === 0 ||
            web3Details.address === ""
        ) {
            return;
        }

        fetchOpenPositions(web3Details.address).then(positionData => {
            const {positions} = getPositions(positionData, data.liveTokens, web3Details.address);
            setOpenPositions(positions);
        });
    };

    const fetchAllUsers = async () => {
        getAllUsers().then(
            users => {
                setUsers(users);
            }
        );
    };

    const fetchDailyStats = async () => {
        getDailyStats().then(stats =>
            setDailyStats(stats)
        );
    };

    const fetchSwychVolume = async () => {
        getSwychVolume().then(volume => {
            if (!volume) return;
            setSwychVolume(volume.reduce((total, data) => total + parseFloat(data.dailyVolumeUSD), 0));
        });
    };

    const fetchWithdrawalFees = async () => {
        getWithdrawalFees().then(fees => {
            const {startOfPreviousDay, endOfPreviousDay} = calculate24hTimestamps();
            setWithdrawalFees(fees);
            setWithdrawalFees24h(fees.filter(fee => fee.timestamp >= startOfPreviousDay && fee.timestamp <= endOfPreviousDay));
        });
    };

    const refreshLiquidity = () => {
        if (!data.liveTokens || Object.keys(data.liveTokens).length === 0) return;

        const liquidity = Object.values(data.liveTokens).reduce((total, token) => {
            if (token.isWrapped) return total;
            return total + token.poolAmount * token.refPrice;
        }, 0n);

        const availableLiquidity = Object.values(data.liveTokens).reduce((total, token) => {
            if (token.isWrapped) return total;
            return total + token.availableUsd;
        }, 0n);

        setTotalLiquidityStr(`$${beautifyWeiNumber(liquidity, 48, 2, true)}`);
        setTotalAvailableLiquidityStr(`$${beautifyWeiNumber(availableLiquidity, 30, 2, true)}`);
        setTotalLiquidityTooltipContent(Object.values(data.liveTokens).map(token => {
            if (token.isWrapped) return null;
            return <div key={token.address} style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                padding: "5px 0",
            }}>
                <span style={{
                    paddingRight: "5px",
                }}>{token.symbol}:</span>
                <span>${beautifyWeiNumber(token.poolAmount * token.refPrice, 48, 2, true)}</span>
            </div>;
        }));
    };

    // Effects.
    useEffect(() => {
        setInterval(() => {
            setUpdateTrigger(prevState => !prevState);
        }, UPDATE_INTERVAL);
    }, []);

    useEffect(() => {
        setDataTimeRange24h(calculate24hTimestamps());
        fetchOrders().then();
        fetchAum().then();
        fetchProtocolRevenue().then();
        fetchSwychDailyPrices().then();
        fetchAllUsers().then();
        fetchDailyStats().then();
        fetchSwychVolume().then();
        fetchWithdrawalFees().then();
    }, [updateTrigger]);

    useEffect(() => {
        if (!web3Details.address) return;

        fetchAccruedPoints(web3Details.address).then();
        fetchUserSwychBalance().then();
        fetchUserClosedPositions().then();
        fetchUserOpenPositions().then();
    }, [web3Details, updateTrigger]);

    useEffect(() => {
        refreshLiquidity();
    }, [data]);

    useEffect(() => {
        fetchUserOpenPositions().then();
    }, [data, web3Details]);

    useEffect(() => {
        if (orders === null) {
            setOrdersStr(VALUE_PLACEHOLDER);
            return;
        }

        setOrdersStr(orders.length.toString());
        const {startOfPreviousDay, endOfPreviousDay} = calculate24hTimestamps();
        setOrders24h(orders.filter(order => order.submissionTimestamp >= startOfPreviousDay && order.submissionTimestamp <= endOfPreviousDay));
        refreshTradingVolume();
        refreshOrdersStatistics();
    }, [orders]);

    useEffect(() => {
        if (orders === null || web3Details.address === "") {
            return;
        }

        calculateUserGeneratedVolume(orders, web3Details.address);
    }, [orders, web3Details]);

    useEffect(() => {
        if (orders24h === null) {
            setOrders24hStr(VALUE_PLACEHOLDER);
            return;
        }

        setOrders24hStr(`+${orders24h.length} (24h)`);
    }, [orders24h]);

    useEffect(() => {
        setOrdersLoading(ordersStr === VALUE_PLACEHOLDER);
    }, [ordersStr]);

    useEffect(() => {
        setOrders24hLoading(orders24hStr === VALUE_PLACEHOLDER);
    }, [orders24hStr]);

    useEffect(() => {
        if (tradingVolume === null) {
            setTradingVolumeStr(VALUE_PLACEHOLDER);
            return;
        }

        setTradingVolumeStr(`$${beautifyWeiNumber(tradingVolume, 30, 2, true)}`);
    }, [tradingVolume]);

    useEffect(() => {
        if (tradingVolume24h === null) {
            setTradingVolume24hStr(VALUE_PLACEHOLDER);
            return;
        }

        setTradingVolume24hStr(`+$${beautifyWeiNumber(tradingVolume24h, 30, 2, true)} (24h)`);
    }, [tradingVolume24h]);

    useEffect(() => {
        setTradingVolumeLoading(tradingVolumeStr === VALUE_PLACEHOLDER);
    }, [tradingVolumeStr]);

    useEffect(() => {
        setTradingVolume24hLoading(tradingVolume24hStr === VALUE_PLACEHOLDER);
    }, [tradingVolume24hStr]);

    useEffect(() => {
        if (aum === null) {
            setAumStr(VALUE_PLACEHOLDER);
            return;
        }

        setAumStr(`$${beautifyWeiNumber(aum, 30, 2, true)}`);
    }, [aum]);

    useEffect(() => {
        setAumLoading(aumStr === VALUE_PLACEHOLDER);
    }, [aumStr]);

    useEffect(() => {
        if (protocolRevenue === null) {
            setProtocolRevenueStr(VALUE_PLACEHOLDER);
            return;
        }

        const totalRevenue = protocolRevenue + withdrawalAmountUsd;
        setProtocolRevenueStr(`$${beautifyWeiNumber(totalRevenue, 30, 2, true)}`);
    }, [protocolRevenue, withdrawalAmountUsd]);

    useEffect(() => {
        setProtocolRevenueLoading(protocolRevenueStr === VALUE_PLACEHOLDER);
    }, [protocolRevenueStr]);

    useEffect(() => {
        if (accruedPoints === null) {
            setAccruedPointsStr(VALUE_PLACEHOLDER);
            return;
        }

        setAccruedPointsStr(beautifyWeiNumber(accruedPoints, 18, 2, true));
    }, [accruedPoints]);

    useEffect(() => {
        setAccruedPointsLoading(accruedPointsStr === VALUE_PLACEHOLDER);
    }, [accruedPointsStr]);

    useEffect(() => {
        if (generatedVolume === null) {
            setGeneratedVolumeStr(VALUE_PLACEHOLDER);
            return;
        }

        setGeneratedVolumeStr(`$${beautifyWeiNumber(generatedVolume, 30, 2, true)}`);
    }, [generatedVolume]);

    useEffect(() => {
        setGeneratedVolumeLoading(generatedVolumeStr === VALUE_PLACEHOLDER);
    }, [generatedVolumeStr]);

    useEffect(() => {
        setSwychPriceData(swychPrices.map((price, ix) => {
            return {
                name: ix.toString(),
                uv: ix,
                pv: price,
                amt: price,
            }
        }));
        setSwychCurrentPrice(swychPrices[swychPrices.length - 1]);
        const firstPrice = swychPrices[0];
        const lastPrice = swychPrices[swychPrices.length - 1];
        const growth = (lastPrice - firstPrice) / firstPrice * 100;
        setSwychGrowth(growth);
    }, [swychPrices]);

    useEffect(() => {
        if (swychCurrentPrice === null) {
            setSwychCurrentPriceStr(VALUE_PLACEHOLDER);
            return;
        }

        setSwychCurrentPriceStr(`$${formatNumberWithCommas(swychCurrentPrice, 4)}`);
    }, [swychCurrentPrice]);

    useEffect(() => {
        if (swychGrowth === null) {
            setSwychGrowthStr(VALUE_PLACEHOLDER);
            return;
        }

        setSwychGrowthStr(`${swychGrowth > 0 ? "+" : ""}${formatNumberWithCommas(swychGrowth, 2)}%`);
    }, [swychGrowth]);

    useEffect(() => {
        if (swychBalance === null) {
            setSwychBalanceStr(VALUE_PLACEHOLDER);
            return;
        }

        setSwychBalanceStr(beautifyWeiNumber(swychBalance, 18, 2, true));
    }, [swychBalance]);

    useEffect(() => {
        setSwychBalanceLoading(swychBalanceStr === VALUE_PLACEHOLDER);
    }, [swychBalanceStr]);

    useEffect(() => {
        if (swychBalance === null || swychPrices.length === 0) {
            setSwychBalanceInUsd(null);
        }

        const balance = beautifyWeiNumber(swychBalance, 18, 2, false);
        const price = swychPrices[swychPrices.length - 1];
        setSwychBalanceInUsd(balance * price);
    }, [swychBalance, swychPrices]);

    useEffect(() => {
        if (swychBalanceInUsd === null) {
            setSwychBalanceInUsdStr(VALUE_PLACEHOLDER);
            return;
        }

        setSwychBalanceInUsdStr(`$${formatNumberWithCommas(swychBalanceInUsd, 2)}`);
    }, [swychBalanceInUsd]);

    useEffect(() => {
        if (closedPositions === null) {
            return;
        }

        const totalClosedPnl = closedPositions.reduce((total, position) => {
            return total + calculatePositionPnl(position);
        }, 0n);

        let accumulatedPnls = [];
        let wins = 0;
        let losses = 0;

        for (let i = 0; i < closedPositions.length; i++) {
            // Sum of all previous pnls + current pnl.
            const position = closedPositions[i];
            const pnl = calculatePositionPnl(position);
            const accumulatedPnl = accumulatedPnls.length === 0 ? pnl : accumulatedPnls[i - 1] + pnl;
            accumulatedPnls.push(accumulatedPnl);

            if (pnl >= 0n) {
                wins++;
            } else if (pnl < 0n) {
                losses++;
            }
        }

        setTotalWins(wins);
        setTotalLosses(losses);
        accumulatedPnls = accumulatedPnls.map(pnl => parseFloat(beautifyWeiNumber(pnl, 30, 4, false)));
        accumulatedPnls = accumulatedPnls.slice(Math.max(accumulatedPnls.length - 20, 0));
        setPnlsData(accumulatedPnls);
        setClosedPositionsPnl(totalClosedPnl);
    }, [closedPositions]);

    useEffect(() => {
        if (openPositions === null || closedPositions === null) {
            return;
        }

        if (openPositions.length === 0 && closedPositions.length === 0) {
            setPnlGrowth(0n);
            return;
        }

        let totalCollateral = 0n;
        let totalPnl = 0n;

        for (let i = 0; i < openPositions.length; i++) {
            totalCollateral += openPositions[i].collateral;
            totalPnl += openPositions[i].signedDeltaAfterFees;
        }

        for (let i = 0; i < closedPositions.length; i++) {
            totalCollateral += closedPositions[i].collateralValue ? toBigInt(closedPositions[i].collateralValue) : 0n;
            totalPnl += calculatePositionPnl(closedPositions[i]);
        }

        const pnlGrowth = parseFloat((totalPnl * 100n * 100n / totalCollateral).toString()) / 100;
        setPnlGrowth(pnlGrowth);
    }, [openPositions, closedPositions]);

    useEffect(() => {
        if (closedPositionsPnl === null || openPositionsPnl === null) {
            return;
        }

        setTotalPnl(closedPositionsPnl + openPositionsPnl);
    }, [closedPositionsPnl, openPositionsPnl]);

    useEffect(() => {
        if (totalPnl === null) {
            setTotalPnlStr(VALUE_PLACEHOLDER);
            return;
        }

        setTotalPnlStr(`$${beautifyWeiNumber(totalPnl, 30, 2, true)}`);
    }, [totalPnl]);

    useEffect(() => {
        if (openPositionsPnl === null) {
            setOpenPositionsPnlStr(VALUE_PLACEHOLDER);
            return;
        }

        setOpenPositionsPnlStr(`$${beautifyWeiNumber(openPositionsPnl, 30, 2, true)}`);
    }, [openPositionsPnl]);

    useEffect(() => {
        setOpenPositionsPnlLoading(openPositionsPnlStr === VALUE_PLACEHOLDER);
    }, [openPositionsPnlStr]);

    useEffect(() => {
        if (!pnlsData || pnlsData.length === 0) {
            return;
        }

        setChartPnls(pnlsData.map((pnl, ix) => {
            return {
                name: ix.toString(),
                uv: ix,
                pv: pnl,
                amt: pnl,
            }
        }));
    }, [pnlsData]);

    useEffect(() => {
        if (pnlGrowth === null) {
            setPnlGrowthStr(VALUE_PLACEHOLDER);
            return;
        }

        setPnlGrowthStr(`${pnlGrowth > 0 ? "+" : ""}${formatNumberWithCommas(pnlGrowth, 2)}%`);
    }, [pnlGrowth]);

    useEffect(() => {
        if (openPositions === null) {
            return;
        }

        if (openPositions.length > 0) {
            const totalPnl = openPositions.reduce((total, position) => {
                return total + position.signedDeltaAfterFees;
            }, 0n);
            const totalPercentage = openPositions.reduce((total, position) => {
                return total + position.signedDeltaPercentage;
            }, 0n);
            setOpenPositionsPnl(totalPnl);
            setOpenPositionsPnlGrowth(totalPercentage);
        } else {
            setOpenPositionsPnl(0n);
            setOpenPositionsPnlGrowth(0n);
        }
    }, [openPositions]);

    useEffect(() => {
        if (openPositionsPnlGrowth === null) {
            setOpenPositionsPnlGrowthStr(VALUE_PLACEHOLDER);
            return;
        }

        setOpenPositionsPnlGrowthStr(`${openPositionsPnlGrowth > 0n ? "+" : ""}${beautifyWeiNumber(openPositionsPnlGrowth, 2, 2, true)}%`);
    });

    useEffect(() => {
        if (users === null) {
            return;
        }

        setUserCountTotal(users.length);
        // 1 week
        const {startOfPreviousWeek, endOfPreviousWeek} = calculate1WeekTimestamps();
        setUserCountNew(users.filter(user => user.timestamp >= startOfPreviousWeek && user.timestamp <= endOfPreviousWeek).length);
        // 24h
        const {startOfPreviousDay, endOfPreviousDay} = calculate24hTimestamps();
        setUserCount24h(users.filter(user => user.timestamp >= startOfPreviousDay && user.timestamp <= endOfPreviousDay).length);
    }, [users]);

    useEffect(() => {
        setUserCountTotalLoading(userCountTotal === null);
    }, [userCountTotal]);

    useEffect(() => {
        setUserCountNewLoading(userCountNew === null);
    }, [userCountNew]);

    useEffect(() => {
        setUserCount24hLoading(userCount24h === null);
    }, [userCount24h]);

    useEffect(() => {
        setUserStats([
            {
                name: "Total Users",
                value: userCountTotal,
                valueLoading: userCountTotalLoading,
                hasTooltip: true,
                tooltipContent: `The total number of unique users that have interacted with the protocol.`,
            },
            {
                name: "New Users",
                value: userCountNew,
                valueLoading: userCountNewLoading,
                hasTooltip: true,
                tooltipContent: `The number of unique users that have started interacting with the protocol in the last 7 days.`,
            },
            {
                name: "New Users (24h)",
                value: userCount24h,
                valueLoading: userCount24hLoading,
                hasTooltip: true,
                tooltipContent: `The number of unique users that have started interacting with the protocol in the last 24 hours.`,
            },
        ]);
    }, [userCountTotal, userCountNew, userCount24h, userCountTotalLoading, userCountNewLoading, userCount24hLoading]);

    useEffect(() => {
        setLongOrdersLoading(longOrdersStr === VALUE_PLACEHOLDER);
        setShortOrdersLoading(shortOrdersStr === VALUE_PLACEHOLDER);
        setLongOrdersVolumeLoading(longOrdersVolumeStr === VALUE_PLACEHOLDER);
        setShortOrdersVolumeLoading(shortOrdersVolumeStr === VALUE_PLACEHOLDER);
        setOrderStats([
            {
                name: "Long Orders",
                value: longOrdersStr,
                valueLoading: longOrdersLoading,
            },
            {
                name: "Short Orders",
                value: shortOrdersStr,
                valueLoading: shortOrdersLoading,
            },
            {
                name: "Long Orders Volume",
                value: longOrdersVolumeStr,
                valueLoading: longOrdersVolumeLoading,
            },
            {
                name: "Short Orders Volume",
                value: shortOrdersVolumeStr,
                valueLoading: shortOrdersVolumeLoading,
            },
        ]);
    }, [
        longOrdersStr, shortOrdersStr, longOrdersVolumeStr, shortOrdersVolumeStr,
        longOrdersLoading, shortOrdersLoading, longOrdersVolumeLoading, shortOrdersVolumeLoading]);

    useEffect(() => {
        if (dailyStats === null || dailyStats.length === 0 || Object.keys(data.livePrices).length === 0) {
            setProtocolRevenue24hStr(VALUE_PLACEHOLDER);
            setAum24hStr(VALUE_PLACEHOLDER);
            return;
        }

        const {protocolRevenue24h, aum24h} = calculateDailyStats(dailyStats, data.livePrices);
        setProtocolRevenue24h(protocolRevenue24h);
        setAum24h(aum24h);
    }, [dailyStats, data.livePrices]);

    useEffect(() => {
        if (protocolRevenue24h === null) {
            setProtocolRevenue24hStr(VALUE_PLACEHOLDER);
            return;
        }

        const totalRevenue = protocolRevenue24h + withdrawalAmountUsd24h;
        setProtocolRevenue24hStr(`${totalRevenue > 0n ? "+" : ""}$${beautifyWeiNumber(totalRevenue, 30, 2, true)} (24h)`);
    }, [protocolRevenue24h]);

    useEffect(() => {
        if (aum24h === null) {
            setAum24hStr(VALUE_PLACEHOLDER);
            return;
        }

        setAum24hStr(`${aum24h > 0n ? "+" : ""}$${beautifyWeiNumber(aum24h, 30, 2, true)} (24h)`);
    }, [aum24h]);

    useEffect(() => {
        setProtocolRevenue24hLoading(protocolRevenue24hStr === VALUE_PLACEHOLDER);
    }, [protocolRevenue24hStr]);

    useEffect(() => {
        setAum24hLoading(aum24hStr === VALUE_PLACEHOLDER);
    }, [aum24hStr]);

    useEffect(() => {
        if (swychVolume === null) {
            setSwychVolumeStr(VALUE_PLACEHOLDER);
            return;
        }

        setSwychVolumeStr(`$${formatNumberWithCommas(swychVolume, 2)}`);
    }, [swychVolume]);

    useEffect(() => {
        setTotalLiquidityLoading(totalLiquidityStr === VALUE_PLACEHOLDER);
        setLiquidityStats([
            {
                name: "Total Liquidity",
                value: totalLiquidityStr,
                valueLoading: totalLiquidityLoading,
                hasTooltip: true,
                tooltipTitle: "Tokens",
                tooltipContent: totalLiquidityTooltipContent,
            },
            {
                name: "Available Liquidity",
                value: totalAvailableLiquidityStr,
                valueLoading: totalLiquidityLoading,
            },
        ]);
    }, [totalLiquidityStr, totalLiquidityLoading]);

    useEffect(() => {
        if (dataTimeRange24h === null) {
            setDataTimeRange24hStr(VALUE_PLACEHOLDER);
            return;
        }

        const start = new Date(dataTimeRange24h.startOfPreviousDay * 1000);
        const startStr = `${start.toLocaleString('default', {month: 'long'})} ${start.getUTCDate()}`;
        setDataTimeRange24hStr(startStr);
    }, [dataTimeRange24h]);

    useEffect(() => {
        if (withdrawalFees === null || data.livePrices === null || Object.keys(data.livePrices).length === 0) {
            return;
        }

        setWithdrawalAmountUsd(calculateWithdrawalAmountUsd(withdrawalFees, data.livePrices));
    }, [withdrawalFees, data.livePrices]);

    useEffect(() => {
        if (withdrawalFees24h === null || data.livePrices === null || Object.keys(data.livePrices).length === 0) {
            return;
        }

        setWithdrawalAmountUsd24h(calculateWithdrawalAmountUsd(withdrawalFees24h, data.livePrices));
    }, [withdrawalFees24h, data.livePrices]);

    useEffect(() => {
        if (totalWins === null || totalLosses === null) {
            setTotalWinLossStr(VALUE_PLACEHOLDER);
            return;
        }

        const totalWinLossRatio = totalWins * 100 / (totalWins + totalLosses);
        setTotalWinLossRatio(totalWinLossRatio);
        setTotalWinLossStr(`${totalWins} / ${totalLosses} (${parseInt(totalWinLossRatio.toString(), 10)}%)`);
    }, [totalWins, totalLosses]);

    useEffect(() => {
        setTotalWinLossLoading(totalWinLossStr === VALUE_PLACEHOLDER);
    }, [totalWinLossStr]);

    return (
        <Layout>
            <div className="dashboard">
                <div className="dashboard-top-section">
                    <InfoBoxBasic title="Trading Volume"
                                  content={tradingVolumeStr}
                                  subcontent={tradingVolume24hStr}
                                  isSubcontentPositive={tradingVolume24h > 0}
                                  hasSubcontentDirection={true}
                                  dataLoading={{
                                      content: tradingVolumeLoading,
                                      subcontent: tradingVolume24hLoading
                                  }}
                                  hasTooltip={true}
                                  tooltipMainContent={`Data shown is for ${dataTimeRange24hStr}, resetting daily at 00:00 UTC.`}
                                  tooltipSecondaryContent={null}
                    />
                    <InfoBoxBasic title="Assets Under Management"
                                  content={aumStr}
                                  subcontent={aum24hStr}
                                  dataLoading={{
                                      content: aumLoading,
                                      subcontent: aum24hLoading
                                  }}
                                  isSubcontentPositive={aum24h > 0}
                                  hasSubcontentDirection={true}
                                  hasTooltip={true}
                                  tooltipMainContent={`Data shown is for ${dataTimeRange24hStr}, resetting daily at 00:00 UTC.`}
                                  tooltipSecondaryContent={null}
                    />
                    <InfoBoxBasic title="Protocol Revenue"
                                  content={protocolRevenueStr}
                                  subcontent={protocolRevenue24hStr}
                                  isSubcontentPositive={protocolRevenue24h > 0}
                                  hasSubcontentDirection={true}
                                  dataLoading={{
                                      content: protocolRevenueLoading,
                                      subcontent: protocolRevenue24hLoading
                                  }}
                                  hasTooltip={true}
                                  tooltipMainContent={`Data shown is for ${dataTimeRange24hStr}, resetting daily at 00:00 UTC.`}
                                  tooltipSecondaryContent={null}
                    />
                    <InfoBoxBasic title="Orders Made"
                                  content={ordersStr}
                                  subcontent={orders24hStr}
                                  isSubcontentPositive={true}
                                  hasSubcontentDirection={true}
                                  dataLoading={{
                                      content: ordersLoading,
                                      subcontent: orders24hLoading
                                  }}
                                  hasTooltip={true}
                                  tooltipMainContent={`Data shown is for ${dataTimeRange24hStr}, resetting daily at 00:00 UTC.`}
                                  tooltipSecondaryContent={null}
                    />
                </div>
                <div className="dashboard-middle-section">
                    <InfoBoxTradingActivity
                        title="Your Trading Activity"
                        activity={{
                            pnlRoe24h: openPositionsPnlStr,
                            isPnlRoe24hDirectionPositive: openPositionsPnlGrowth > 0,
                            pnlRoe24hPercentage: openPositionsPnlGrowthStr,
                            generatedVolume: generatedVolumeStr,
                            accruedPoints: accruedPointsStr,
                            pnlRoeValue: totalPnlStr,
                            pnlRoeChange: pnlGrowthStr,
                            pnlRoeChangePositive: pnlGrowth > 0,
                            tradingData: chartPnls,
                            winsAndLosses: totalWinLossStr,
                        }}
                        dataLoading={{
                            accruedPoints: accruedPointsLoading,
                            generatedVolume: generatedVolumeLoading,
                            pnlRoe24h: openPositionsPnlLoading,
                            winsAndLosses: totalWinLossLoading,
                        }}
                    />
                    <InfoBoxSwychStatistics
                        data={{
                            swychTokenPriceChange: swychGrowthStr,
                            swychTokenPriceChangePositive: swychGrowth > 0,
                            swychHoldings: swychBalanceStr,
                            swychHoldingsInUsd: swychBalanceInUsdStr,
                            swychTokenPrice: swychCurrentPriceStr,
                            swychTokenVolume: swychVolumeStr,
                            swychPriceData: swychPriceData
                        }}
                        dataLoading={{
                            swychHoldings: swychBalanceLoading,
                        }}
                    />
                </div>
                <div className="dashboard-bottom-section">
                    <InfoBoxStats data={userStats} title={"Userbase"}/>
                    <InfoBoxStats data={orderStats} title={"Orders"}/>
                    <InfoBoxStats data={liquidityStats} title={"Liquidity"}/>
                </div>
            </div>
        </Layout>
    );
}

export default Dashboard;
