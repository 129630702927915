import "./ExternalLinkButton.css";

export default function ExternalLinkButton(props) {
    const handleTradeButtonClick = () => {
        window.open(props.link, props.newTab ? "_blank" : "_self");
    };

    return (
        <button className={props.className ? props.className : "external-link-button"} onClick={handleTradeButtonClick}>
            {props.name}
        </button>
    );
};
