import {
    queryClosedPositions,
    queryDailyStats,
    queryOrders,
    querySwychDailyPrice,
    querySwychVolume,
    queryUsers, queryWithdrawalFees
} from "./subgraphs";
import axios from "axios";
import {ENDPOINTS} from "../constants/endpoints";

const getOrders = async (address = null) => {
    return queryOrders(address, false);
};

const getOrders24h = async (address) => {
    return queryOrders(address, true);
};

const getSwychDailyPrice = async (atLeastDays) => {
    return querySwychDailyPrice(atLeastDays);
};

const getClosedPositions = async (address) => {
    return queryClosedPositions(address);
};

const getAllLivePrices = async () => {
    return axios.get(ENDPOINTS.livePrices);
};

const getAllUsers = async () => {
    return queryUsers();
};

const getDailyStats = async () => {
    return queryDailyStats();
};

const getSwychVolume = async () => {
    return querySwychVolume();
};

const getWithdrawalFees = async (from) => {
    return queryWithdrawalFees(from);
};

export {
    getOrders,
    getOrders24h,
    getSwychDailyPrice,
    getClosedPositions,
    getAllLivePrices,
    getAllUsers,
    getDailyStats,
    getSwychVolume,
    getWithdrawalFees
};
