const generateOrdersQuery = (userAddress, timeStart = 0, skip = 0, first = 1000) => {
    return {
        query: `
        {
          orders(
            where: {
              ${userAddress ? `owner: "${userAddress}",` : ``} 
              submissionTimestamp_gt: "${timeStart}"
            },
            first: ${first},
            skip: ${skip},
            orderBy: submissionTimestamp,
            orderDirection: asc
          ) {
              id
              side
              type
              status
              submissionTimestamp
              collateralValue
              sizeChange
              updateType
              owner
          }
        }`
    };
};

const generateSwychDailyPriceQuery = (timeStart = 0, skip = 0, first = 1000) => {
    return {
        query: `
        {
          dailyPrices(
            where: {createdAt_gt: "${timeStart}"},
            first: ${first},
            skip: ${skip},
            orderBy: createdAt,
            orderDirection: asc
          ) {
              id
              price
              reserveBUSD
              reserveToken
              createdAt
            }
        }`
    };
};

const generateClosedPositionsQuery = (userAddress, skip = 0, first = 1000) => {
    return {
        query: `
        {
          histories(
            where: {
              owner: "${userAddress}", 
              pnl_not: "0", 
              status_in: [FILLED, LIQUIDATED]
            },
            first: ${first},
            skip: ${skip},
            orderBy: createdAtTimestamp,
            orderDirection: asc
          ) {
              pnl
              status
              borrowFeeValue
              borrowFeeValuePartial
              closeFeeValue
              closeFeeValuePartial
              collateralValue
              createdAtTimestamp
            }
        }
        `
    };
};

const generateUsersQuery = (timeStart = 0, skip = 0, limit = 1000) => {
    return {
        query: `
        {
          users(
            where: {timestamp_gt: "${timeStart}"},
            first: ${limit},
            skip: ${skip},
            orderBy: timestamp,
            orderDirection: asc
          ) {
              id
              timestamp
            }
        }
        `
    };
};

const generateDailyExchangeStatsQuery = (timeStart = 0, timeEnd = 0, skip = 0, limit = 1000) => {
    return {
        query: `
        {
          poolStats(
              skip: ${skip}, 
              first: ${limit}, 
              where: {timestamp_gt: "${timeStart}", timestamp_lt: "${timeEnd}"}, 
              orderBy: timestamp, 
              orderDirection: asc
          ) {
            aum
            revenueTotalUsd
            revenuePoolUsd
            feesUsd
            feesBnb
            feesBtc
            feesEth
            feesSol
            timestamp
          }
        }
        `
    };
};

const generateSwychVolumeQuery = (skip = 0, limit = 1000) => {
    return {
        query: `
        {
          tokenDayDatas(
            skip: ${skip},
            first: ${limit},
            where: {token_: {name: "Swych"}}
          ) {
            dailyVolumeUSD
          }
        }
        `
    };
};

const generateWithdrawalFeesQuery = (timeStart = 0, skip = 0, limit = 1000) => {
    return {
        query: `
            {
              feeWithdrawals(
                first: ${limit}
                skip: ${skip}
                orderBy: timestamp
                orderDirection: asc
                where: {timestamp_gt: "${timeStart}"}
              ) {
                amount
                id
                recipient
                timestamp
                token
              }
            }`
    };
};

export {
    generateOrdersQuery,
    generateSwychDailyPriceQuery,
    generateClosedPositionsQuery,
    generateUsersQuery,
    generateDailyExchangeStatsQuery,
    generateSwychVolumeQuery,
    generateWithdrawalFeesQuery
};
