import axios from "axios";
import {
    generateOrdersQuery,
    generateSwychDailyPriceQuery,
    generateClosedPositionsQuery,
    generateUsersQuery,
    generateDailyExchangeStatsQuery, generateSwychVolumeQuery, generateWithdrawalFeesQuery
} from "./subgraphQueries";
import {SUBGRAPHS} from "../constants/subgraphs";
import {calculate24hTimestamps} from "../utils/utils";

const queryOrders = async (userAddress, forOneDay = false) => {
    const timeStart = forOneDay ? Math.floor(Date.now() / 1000) - 86400 : 0;
    const orders = [];
    const pageSize = 1000;
    let hasMoreData = true;

    while (hasMoreData) {
        const query = generateOrdersQuery(userAddress, timeStart, orders.length, pageSize);
        const response = await axios.post(SUBGRAPHS.pdex, query);
        const fetchedOrders = response?.data?.data?.orders;
        if (!fetchedOrders) {
            hasMoreData = false;
            continue;
        }
        orders.push(...fetchedOrders);
        hasMoreData = fetchedOrders.length === pageSize;
    }

    return orders;
};

const querySwychDailyPrice = async (atLeastDays = 10) => {
    const timeStart = Math.floor(Date.now() / 1000) - 86400 * 60;
    const prices = [];
    const pageSize = 1000;
    let hasMoreData = true;

    while (hasMoreData) {
        const query = generateSwychDailyPriceQuery(timeStart, prices.length, pageSize);
        const response = await axios.post(SUBGRAPHS.swychDailyPrice, query);
        const fetchedPrices = response?.data?.data?.dailyPrices;
        if (!fetchedPrices) {
            hasMoreData = false;
            continue;
        }
        prices.push(...fetchedPrices);
        hasMoreData = fetchedPrices.length === pageSize;
    }

    return prices.slice(-atLeastDays);
}

const queryClosedPositions = async (userAddress) => {
    const positions = [];
    const pageSize = 1000;
    let hasMoreData = true;

    while (hasMoreData) {
        const query = generateClosedPositionsQuery(userAddress, positions.length, pageSize);
        const response = await axios.post(SUBGRAPHS.pdex, query);
        const fetchedPositions = response?.data?.data?.histories;
        if (!fetchedPositions) {
            hasMoreData = false;
            continue;
        }
        positions.push(...fetchedPositions);
        hasMoreData = fetchedPositions.length === pageSize;
    }

    return positions;
};

const queryUsers = async () => {
    const timeStart = 0;
    const users = [];
    const pageSize = 1000;
    let hasMoreData = true;

    while (hasMoreData) {
        const query = generateUsersQuery(timeStart, users.length, pageSize);
        const response = await axios.post(SUBGRAPHS.pdex, query);
        const fetchedUsers = response?.data?.data?.users;
        if (!fetchedUsers) {
            hasMoreData = false;
            continue;
        }
        users.push(...fetchedUsers);
        hasMoreData = fetchedUsers.length === pageSize;
    }

    return users;
};

const queryDailyStats = async () => {
    // 24h stats
    const {startOfPreviousDay, endOfPreviousDay} = calculate24hTimestamps();
    const timeStart = Math.round(startOfPreviousDay);
    const timeEnd = Math.round(endOfPreviousDay);
    const stats = [];
    const pageSize = 1000;
    let hasMoreData = true;

    while (hasMoreData) {
        const query = generateDailyExchangeStatsQuery(timeStart, timeEnd, stats.length, pageSize);
        const response = await axios.post(SUBGRAPHS.pdex, query);
        const fetchedStats = response?.data?.data?.poolStats;
        if (!fetchedStats) {
            hasMoreData = false;
            continue;
        }
        stats.push(...fetchedStats);
        hasMoreData = fetchedStats.length === pageSize;
    }

    return stats;
};

const querySwychVolume = async () => {
    const data = [];
    const pageSize = 1000;
    let hasMoreData = true;

    while (hasMoreData) {
        const query = generateSwychVolumeQuery(data.length, pageSize);
        const response = await axios.post(SUBGRAPHS.swychData, query);
        const fetchedData = response?.data?.data?.tokenDayDatas;
        if (!fetchedData) {
            hasMoreData = false;
            continue;
        }
        data.push(...fetchedData);
        hasMoreData = fetchedData.length === pageSize;
    }

    return data;
};

const queryWithdrawalFees = async (from) => {
    const data = [];
    const pageSize = 1000;
    let hasMoreData = true;

    while (hasMoreData) {
        const query = generateWithdrawalFeesQuery(from, data.length, pageSize);
        const response = await axios.post(SUBGRAPHS.pdex, query);
        const fetchedData = response?.data?.data?.feeWithdrawals;
        if (!fetchedData) {
            hasMoreData = false;
            continue;
        }
        data.push(...fetchedData);
        hasMoreData = fetchedData.length === pageSize;
    }

    return data;
};

export {
    queryOrders,
    querySwychDailyPrice,
    queryClosedPositions,
    queryUsers,
    queryDailyStats,
    querySwychVolume,
    queryWithdrawalFees
};
