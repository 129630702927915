import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import {OffCanvas, OffCanvasMenu, OffCanvasBody} from "react-offcanvas";
import routes from "../../constants/routes";
import "./HeaderMenu.css";
import {Fade as Hamburger} from 'hamburger-react'

function HeaderMenu(props) {
    const {isMobile} = props;
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

    const isActive = (route) => {
        return window.location.pathname === route.path;
    };

    return (
        <>
            {/* Desktop Menu */}
            {!isMobile && (
                <div className="header-menu">
                    {Object.values(routes).map((route) => (
                        <Link
                            key={route.path}
                            to={route.path}
                            className={"header-menu-item" + (isActive(route) ? " active" : "")}
                            target={route.newTab ? "_blank" : "_self"}
                        >
                            {route.name}
                        </Link>
                    ))}
                </div>
            )}

            {/* Mobile Menu (Burger Menu) */}
            {isMobile && (
                <>
                    <Hamburger toggled={isMobileMenuOpen} toggle={setIsMobileMenuOpen} color={"#fff"} size={20}/>
                    <OffCanvas
                        width={300}
                        transitionDuration={300}
                        isMenuOpened={isMobileMenuOpen}
                        position={"left"}
                        effect={"overlay"}
                    >
                        <OffCanvasMenu className={"mobile-menu"}>
                            <div className={"mobile-menu-links"}>
                                {Object.values(routes).map((route) => (
                                    <Link
                                        key={route.path}
                                        to={route.path}
                                        className={"header-menu-item" + (isActive(route) ? " active" : "")}
                                        target={route.newTab ? "_blank" : "_self"}
                                    >
                                        {route.name}
                                    </Link>
                                ))}
                            </div>
                        </OffCanvasMenu>
                    </OffCanvas>
                </>
            )}
        </>
    );
}

export default HeaderMenu;
