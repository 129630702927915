export const SIDE = {
    LONG: 0,
    SHORT: 1,
};

export const TYPE = {
    MARKET: "MARKET",
    LIMIT: "LIMIT",
};

export const STATUS = {
    OPEN: "OPEN",
    CANCELLED: "CANCELLED",
    FILLED: "FILLED",
    EXPIRED: "EXPIRED",
};

export const DIRECTION = {
    INCREASE: "INCREASE",
    DECREASE: "DECREASE",
};

export const FUNDING_RATE_PRECISION = 10000000000n;
export const MARGIN_FEE_BASIS_POINTS = 10n;
export const BASIS_POINTS_DIVISOR = 10000n;
