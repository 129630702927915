export const availableTokens = {
    BTC: {
        name: "Bitcoin",
        symbol: "BTC",
        decimals: 18,
        address: "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
        isShortable: true,
    },
    BNB: {
        name: "BNB",
        symbol: "BNB",
        decimals: 18,
        address: "0x0000000000000000000000000000000000000000",
        isNative: true,
        isShortable: true,
    },
    WBNB: {
        name: "WBNB",
        symbol: "WBNB",
        baseSymbol: "BNB",
        decimals: 18,
        address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
        isShortable: true,
        isWrapped: true,
    },
    ETH: {
        name: "Wrapped Ethereum",
        symbol: "ETH",
        decimals: 18,
        address: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
        isShortable: true,
        baseSymbol: "ETH",
    },
    USDT: {
        name: "Tether",
        symbol: "USDT",
        decimals: 18,
        address: "0x55d398326f99059fF775485246999027B3197955",
        isStable: true,
    },
    SOL: {
        name: "Solana",
        symbol: "SOL",
        decimals: 18,
        address: "0x570A5D26f7765Ecb712C0924E4De545B89fD43dF",
        isShortable: true,
    },
};

export const availableTokenList = Object.values(availableTokens).map((token) => token.address);
export const wrappedNativeToken = availableTokens.WBNB;

export const feeTokenList = Object.values([
    availableTokens.USDT.address,
    availableTokens.WBNB.address,
    availableTokens.BTC.address,
    availableTokens.ETH.address,
    availableTokens.SOL.address,
]);
