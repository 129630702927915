const routes = {
  DASHBOARD: {
    path: "/",
    name: "Dashboard",
    newTab: false,
  },
  SWAP: {
    path: "https://app.swych.finance/",
    name: "Swap",
    newTab: true,
  },
  PERPETUAL: {
    path: "https://trade.swych.finance/#/trade",
    name: "Perpetual",
    newTab: true,
  },
  LAUNCHPAD: {
    path: "https://app.swych.finance/launchpad",
    name: "Launchpad",
    newTab: true,
  },
  SUPERLOTTO: {
    path: "https://app.swych.finance/superlotto",
    name: "SuperLotto",
    newTab: true,
  },
};

export default routes;
