import {defaultProviderUrls} from "../constants/web3";
import {ethers, toBigInt, ZeroAddress} from "ethers";
import {
    BASIS_POINTS_DIVISOR,
    DIRECTION,
    FUNDING_RATE_PRECISION,
    MARGIN_FEE_BASIS_POINTS,
    STATUS
} from "../constants/pdex";
import {availableTokens} from "../constants/tokens";

export const formatEthAddress = (address, format) => {
    if (!address) {
        return '';
    }

    const prefix = format.beautify ? "0×" : "0x";

    if (address.includes("0x")) {
        address = address.replace("0x", "");
    }

    if (format.toUpper) {
        address = address.toUpperCase();
    }

    if (format.truncate) {
        const truncateRegex = /^([a-zA-Z0-9]{4})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/;
        const match = address.match(truncateRegex);
        if (!match) return address;
        address = `${match[1]}…${match[2]}`;
    }

    return prefix + address;
};

export const getRandomProviderUrl = () => {
    return defaultProviderUrls[Math.floor(Math.random() * defaultProviderUrls.length)];
};

export const beautifyWeiNumber = (num, decimals = 18, decimalPlaces, useCommas) => {
    if (!num) {
        return 0;
    }

    const number = toBigInt(num);
    const wei = 10n ** toBigInt(decimals - decimalPlaces);
    let formattedNumber = (Number(number / wei) / 10 ** decimalPlaces).toFixed(decimalPlaces);

    if (useCommas) {
        formattedNumber = formatNumberWithCommas(formattedNumber, decimalPlaces);
    }

    return formattedNumber;
};

export const beautifySwychDailyPrices = (prices) => {
    return prices.map((price) => {
        return parseFloat(beautifyWeiNumber(toBigInt(price.price), 18, 6, false));
    })
};

export const calculateTradingVolumeFromOrders = (orders) => {
    const filteredOrders = orders.filter(
        order => order.status === STATUS.FILLED
    );
    const {startOfPreviousDay, endOfPreviousDay} = calculate24hTimestamps();
    const filteredOrders24h = filteredOrders.filter(
        order => order.submissionTimestamp >= startOfPreviousDay
            && order.submissionTimestamp <= endOfPreviousDay
    );
    const tradingVolume = filteredOrders.reduce(
        (acc, order) => acc + toBigInt(order.sizeChange),
        toBigInt(0)
    );
    const tradingVolume24h = filteredOrders24h.reduce(
        (acc, order) => acc + toBigInt(order.sizeChange),
        toBigInt(0)
    );
    return {
        tradingVolume,
        tradingVolume24h,
    };
};

export const calculateGeneratedVolume = (orders, userAddress) => {
    const filteredOrders = orders.filter(
        order => order.status === STATUS.FILLED
            && order.updateType === DIRECTION.INCREASE
            && order.owner.toLowerCase() === userAddress.toLowerCase()
    );
    return filteredOrders.reduce(
        (acc, order) => acc + toBigInt(order.sizeChange),
        toBigInt(0));
};

export const formatNumberWithCommas = (number, decimalPlaces) => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces,
    });

    return formatter.format(number);
};

export const calculatePositionPnl = (position) => {
    if (!position) return;
    if (!position.pnl) return;

    const closeFee = position.closeFeeValue ? toBigInt(position.closeFeeValue) * 2n : 0n;
    const closeFeePartial = position.closeFeeValuePartial ? toBigInt(position.closeFeeValuePartial) : null;
    const borrowFeePartial = position.borrowFeeValuePartial ? toBigInt(position.borrowFeeValuePartial) : null;
    const borrowFee = position.borrowFeeValue ? toBigInt(position.borrowFeeValue) : 0n;
    const positionPnl = toBigInt(position.pnl);
    const collateralValue = position.collateralValue ? toBigInt(position.collateralValue) : 0n;
    let pnl;

    if (!position.status === "LIQUIDATED") {
        if (borrowFeePartial && closeFeePartial) {
            pnl = positionPnl - closeFeePartial * 2n - borrowFeePartial;
        } else if (borrowFee) {
            pnl = positionPnl + collateralValue - closeFee - borrowFee;
        }
    } else {
        pnl = positionPnl - closeFee - borrowFee;
    }

    return pnl;
};

export const getPositions = (data, tokenData, account) => {
    const propsLength = 9;
    const positions = [];
    const positionsMap = {};
    const {positionData, positionQuery} = data;

    if (!positionData) {
        return {positions, positionsMap};
    }

    const {collateralTokens, indexTokens, isLong} = positionQuery;
    const keysAdded = [];
    for (let i = 0; i < collateralTokens.length; i++) {
        const collateralToken = getTokenData(tokenData, collateralTokens[i]);
        const indexToken = getTokenData(tokenData, indexTokens[i]);
        const key = getPositionKey(account, collateralTokens[i], indexTokens[i], isLong[i]);
        let contractKey;

        if (account) {
            contractKey = getPositionContractKey(account, collateralTokens[i], indexTokens[i], isLong[i]);
        }

        const position = {
            key,
            contractKey,
            collateralToken,
            indexToken,
            isLong: isLong[i],
            size: toBigInt(positionData[i * propsLength]),
            collateral: toBigInt(positionData[i * propsLength + 1]),
            averagePrice: toBigInt(positionData[i * propsLength + 2]) * 1000000000000000000n,
            entryPrice: toBigInt(positionData[i * propsLength + 2]) * 1000000000000000000n,
            entryFundingRate: toBigInt(positionData[i * propsLength + 3]),
            cumulativeFundingRate: collateralToken.cumulativeFundingRate,
            hasRealisedProfit: toBigInt(positionData[i * propsLength + 4]) === 1n,
            realisedPnl: toBigInt(positionData[i * propsLength + 5]),
            lastIncreasedTime: toBigInt(positionData[i * propsLength + 6]),
            hasProfit: toBigInt(positionData[i * propsLength + 7]) === 1n,
            delta: toBigInt(positionData[i * propsLength + 8]),
            markPrice: indexToken.refPrice,
            refPrice: indexToken.refPrice,
        };

        let fundingFee = getFundingFee(position);
        position.fundingFee = fundingFee ? fundingFee : 0n;
        position.collateralAfterFee = position.collateral - position.fundingFee;
        position.closingFee = position.size * MARGIN_FEE_BASIS_POINTS / BASIS_POINTS_DIVISOR;
        position.positionFee = position.size * MARGIN_FEE_BASIS_POINTS * 2n / BASIS_POINTS_DIVISOR;
        position.totalFees = position.positionFee + position.fundingFee;
        position.pendingDelta = position.delta;

        if (position.collateral > 0n) {
            position.hasLowCollateral =
                position.collateralAfterFee < 0n || position.size / abs(position.collateralAfterFee) > 50n;

            if (position.averagePrice && position.markPrice) {
                const priceDelta = position.averagePrice > position.markPrice
                    ? position.averagePrice - position.markPrice
                    : position.markPrice - position.averagePrice;
                position.pendingDelta = position.size * priceDelta / position.averagePrice;
                position.delta = position.pendingDelta;

                if (position.isLong) {
                    position.hasProfit = position.markPrice >= position.averagePrice;
                } else {
                    position.hasProfit = position.markPrice <= position.averagePrice;
                }
            }

            position.deltaPercentage = position.pendingDelta * BASIS_POINTS_DIVISOR / position.collateral;

            let hasProfitAfterFees;
            let pendingDeltaAfterFees;

            if (position.hasProfit) {
                if (position.pendingDelta > position.totalFees) {
                    hasProfitAfterFees = true;
                    pendingDeltaAfterFees = position.pendingDelta - position.totalFees;
                } else {
                    hasProfitAfterFees = false;
                    pendingDeltaAfterFees = position.totalFees - position.pendingDelta;
                }
            } else {
                hasProfitAfterFees = false;
                pendingDeltaAfterFees = position.pendingDelta + position.totalFees;
            }

            position.hasProfitAfterFees = hasProfitAfterFees;
            position.pendingDeltaAfterFees = pendingDeltaAfterFees;
            position.deltaPercentageAfterFees = position.pendingDeltaAfterFees
                * BASIS_POINTS_DIVISOR
                / (position.collateral + position.closingFee);

            let netValue = position.hasProfit
                ? position.collateral + position.pendingDelta
                : position.collateral - position.pendingDelta;

            netValue = netValue - position.fundingFee - position.closingFee;
            position.netValue = netValue;
        }

        position.signedDeltaAfterFees = position.hasProfitAfterFees ? position.pendingDeltaAfterFees : -position.pendingDeltaAfterFees;
        position.signedDeltaPercentage = position.hasProfit ? position.deltaPercentageAfterFees : -position.deltaPercentageAfterFees;

        positionsMap[key] = position;

        if (position.size > 0n && !keysAdded.includes(key)) {
            positions.push(position);
            keysAdded.push(key);
        }
    }

    return {positions, positionsMap};
};

const getTokenData = (tokenData, address) => {
    if (address.toLowerCase() === availableTokens.WBNB.address.toLowerCase() ||
        address.toLocaleLowerCase() === "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE".toLocaleLowerCase()
    ) {
        return tokenData[ZeroAddress];
    }

    return tokenData[address];
};

const getPositionKey = (
    account,
    collateralTokenAddress,
    indexTokenAddress,
    isLong,
    nativeTokenAddress
) => {
    const tokenAddress0 = collateralTokenAddress === ZeroAddress ? nativeTokenAddress : collateralTokenAddress;
    const tokenAddress1 = indexTokenAddress === ZeroAddress ? nativeTokenAddress : indexTokenAddress;
    return account + ":" + tokenAddress0 + ":" + tokenAddress1 + ":" + isLong;
};

export const getPositionContractKey = (account, collateralToken, indexToken, isLong) => {
    return ethers.solidityPackedKeccak256(
        ["address", "address", "address", "bool"],
        [account, collateralToken, indexToken, isLong]
    );
};

export const getFundingFee = (data) => {
    let {entryFundingRate, cumulativeFundingRate, size} = data;

    if (entryFundingRate && cumulativeFundingRate) {
        return size * (cumulativeFundingRate - entryFundingRate) / FUNDING_RATE_PRECISION;
    }

    return;
};

export const calculateDailyStats = (dailyStats, prices) => {
    const size = dailyStats.length;
    const feesUsdt = toBigInt(dailyStats[size - 1].feesUsd) - toBigInt(dailyStats[0].feesUsd);
    const feesBnb = toBigInt(dailyStats[size - 1].feesBnb) - toBigInt(dailyStats[0].feesBnb);
    const feesBtc = toBigInt(dailyStats[size - 1].feesBtc) - toBigInt(dailyStats[0].feesBtc);
    const feesEth = toBigInt(dailyStats[size - 1].feesEth) - toBigInt(dailyStats[0].feesEth);
    const feesSol = toBigInt(dailyStats[size - 1].feesSol) - toBigInt(dailyStats[0].feesSol);
    const feesUsdtInUsd = feesUsdt * toBigInt(prices[availableTokens.USDT.address.toLowerCase()]) / 10n ** 18n;
    const feesBnbInUsd = feesBnb * toBigInt(prices[availableTokens.WBNB.address.toLowerCase()]) / 10n ** 18n;
    const feesBtcInUsd = feesBtc * toBigInt(prices[availableTokens.BTC.address.toLowerCase()]) / 10n ** 18n;
    const feesEthInUsd = feesEth * toBigInt(prices[availableTokens.ETH.address.toLowerCase()]) / 10n ** 18n;
    const feesSolInUsd = feesSol * toBigInt(prices[availableTokens.SOL.address.toLowerCase()]) / 10n ** 18n;
    const protocolRevenue24h = feesUsdtInUsd + feesBnbInUsd + feesBtcInUsd + feesEthInUsd + feesSolInUsd;
    const aum24h = toBigInt(dailyStats[size - 1].aum) - toBigInt(dailyStats[0].aum);
    return {
        protocolRevenue24h,
        aum24h,
    };
};

export const calculateWithdrawalAmountUsd = (withdrawalFees, prices) => {
    let totalWithdrawalAmountUsd = 0n;

    for (const fee of withdrawalFees) {
        const token = fee.token.toLowerCase();
        const amount = toBigInt(fee.amount);
        const price = toBigInt(prices[token]);
        const amountUsd = amount * price / 10n ** 18n;
        totalWithdrawalAmountUsd += amountUsd;
    }

    return totalWithdrawalAmountUsd;
};

export const calculate24hTimestamps = () => {
    const currentDate = new Date();
    const currentUtcDate = new Date(currentDate.toUTCString());
    currentUtcDate.setUTCHours(0, 0, 0, 0);
    const startOfPreviousDay = (currentUtcDate.getTime() - 24 * 60 * 60 * 1000) / 1000;
    const endOfPreviousDay = (currentUtcDate.getTime() - 1) / 1000;

    return {
        startOfPreviousDay,
        endOfPreviousDay,
    };
};

export const calculate1WeekTimestamps = () => {
    const currentDate = new Date();
    const currentUtcDate = new Date(currentDate.toUTCString());
    currentUtcDate.setUTCHours(0, 0, 0, 0);
    const startOfPreviousWeek = (currentUtcDate.getTime() - 7 * 24 * 60 * 60 * 1000) / 1000;
    const endOfPreviousWeek = (currentUtcDate.getTime() - 1) / 1000;

    return {
        startOfPreviousWeek,
        endOfPreviousWeek,
    };
}

const abs = (x) => {
    return x < 0n ? -x : x
};
