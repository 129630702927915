import "./WalletConnect.css";
import "../../assets/styles/general.css"
import React, {useEffect, useState} from "react";
import {useDisconnect, useWeb3ModalAccount, useWeb3Modal} from "@web3modal/ethers/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Davatar from "@davatar/react";
import {faEllipsisV, faWallet} from "@fortawesome/free-solid-svg-icons";
import {LOGOS} from "../../constants/media";
import {formatEthAddress} from "../../utils/utils";
import {defaultWeb3Details, Web3Context, Web3DispatchContext} from "../../contexts/Web3Context/Web3Context";

export default function WalletConnect() {
    const {address, isConnected} = useWeb3ModalAccount();
    const {open} = useWeb3Modal();
    const {disconnect} = useDisconnect();
    const web3Details = React.useContext(Web3Context);
    const setWeb3Details = React.useContext(Web3DispatchContext);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
    const [isSettingsOpen, setIsSettingsOpen] = useState(false);

    const openConnect = async () => {
        await open();
    };

    const disconnectWallet = async () => {
        setIsSettingsOpen(false);
        await disconnect();
    };

    const toggleSettings = () => {
        setIsSettingsOpen(!isSettingsOpen);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1024);
        };
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        if (!isConnected) {
            setWeb3Details(defaultWeb3Details);
            return;
        }

        setWeb3Details({
            ...web3Details,
            address: address || "",
            chainId: "56",
            isConnected: isConnected,
            // provider: walletProvider
        });
    }, [isConnected]);

    return (
        <>
            {isMobile &&
                <>
                    <img src={LOGOS.BNB} className="logo-bnb" alt="logo-bnb"/>
                    {
                        !isConnected &&
                        <button className="btn-wallet-connect" onClick={openConnect}><span
                            className="btn-wallet-connect-label"><FontAwesomeIcon
                            icon={faWallet}/><span>Connect</span></span></button>
                    }
                    {
                        isConnected && web3Details.address &&
                        <div className="menu-settings-button" onClick={toggleSettings}>
                            <Davatar address={web3Details.address} provider={web3Details.provider} size={24}/>
                            <span className="wallet-address-label">{formatEthAddress(web3Details.address, {
                                toUpper: true,
                                truncate: true,
                                beautify: true
                            })}</span>
                            <FontAwesomeIcon icon={faEllipsisV} className="menu-settings-icon"/>
                        </div>
                    }
                    {
                        isSettingsOpen &&
                        <div className="menu-settings">
                            <div className="menu-settings-item">
                                <button className="btn-wallet-connect" onClick={disconnectWallet}>Disconnect</button>
                            </div>
                        </div>

                    }
                </>
            }
            {!isMobile &&
                <>
                    <img src={LOGOS.BNB} className="logo-bnb" alt="logo-bnb"/>
                    <span className="chain-label">BNB Chain</span>
                    {isConnected && web3Details.address &&
                        <>
                            <div className="vertical-separator"/>
                            <Davatar address={web3Details.address} provider={web3Details.provider} size={24}/>
                            <span className="wallet-address-label">{formatEthAddress(web3Details.address, {
                                toUpper: true,
                                truncate: true,
                                beautify: true
                            })}</span>
                        </>
                    }
                    {
                        !isConnected &&
                        <button className="btn-wallet-connect" onClick={openConnect}><span
                            className="btn-wallet-connect-label"><FontAwesomeIcon
                            icon={faWallet}/><span>Connect</span></span></button>
                    }
                    {
                        isConnected &&
                        <button className="btn-wallet-connect" onClick={disconnectWallet}>Disconnect</button>
                    }
                </>
            }
        </>
    )
}