import {LOGOS} from "../../constants/media";
import HeaderMenu from "../HeaderMenu/HeaderMenu";
import React, {useEffect, useState} from "react";
import "./Header.css";
import WalletConnect from "../WalletConnect/WalletConnect";

export default function Header() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1024);
        };
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <header className="header">
            <div className="header-container">
                {isMobile && <HeaderMenu isMobile={isMobile}/>}
                <img src={LOGOS.WHITE} className="logo" alt="logo"/>
                {!isMobile && <HeaderMenu isMobile={isMobile}/>}
                <div className="header-wallet-connect">
                    <WalletConnect/>
                </div>
            </div>
        </header>
    )
};