import React from "react";
import "./Content.css";

export default function Content({children}) {
    return (
        <main className="content">
            <div className="content-container">
                {children}
            </div>
        </main>
    )
}