import React, {createContext, useState} from "react";
import {JsonRpcProvider} from "ethers";
import {getRandomProviderUrl} from "../../utils/utils";

const Web3Context = createContext(undefined);
const Web3DispatchContext = createContext(undefined);
const defaultWeb3Details = {
    address: "",
    chainId: "56",
    isConnected: false,
    provider: new JsonRpcProvider(getRandomProviderUrl()),
};

function Web3Provider({children}) {
    const [web3Details, setWeb3Details] = useState(defaultWeb3Details);

    return (
        <Web3Context.Provider value={web3Details}>
            <Web3DispatchContext.Provider value={setWeb3Details}>
                {children}
            </Web3DispatchContext.Provider>
        </Web3Context.Provider>
    );
}

export {Web3Provider, Web3Context, Web3DispatchContext, defaultWeb3Details};
