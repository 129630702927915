import "@fontsource/inter";
import "./App.css";
import {createWeb3Modal, defaultConfig} from "@web3modal/ethers/react";
import {metadata, bsc, projectId} from "./constants/web3";
import {HashRouter, Route, Routes} from "react-router-dom";
import Dashboard from "./pages/Dashboard/Dashboard";
import {Web3Provider} from "./contexts/Web3Context/Web3Context";
import {DataProvider} from "./contexts/DataContext/DataContext";

createWeb3Modal({
    ethersConfig: defaultConfig({metadata}),
    chains: [bsc],
    projectId,
    themeMode: "dark",
    defaultChain: "bsc"
});

function App() {
    return (
        <Web3Provider>
            <DataProvider>
                <HashRouter>
                    <Routes>
                        <Route path="/" element={<Dashboard/>}/>
                    </Routes>
                </HashRouter>
            </DataProvider>
        </Web3Provider>
    );
}

export default App;
