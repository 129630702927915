import {LOGOS} from "./media";
import {ethers} from "ethers";

export const metadata = {
    name: "SwychWeb3Modal",
    description: "Web3Modal for Swych",
    url: "https://swych.finance/",
    icons: [LOGOS.BLUE_ICON],
};
export const bsc = {
    chainId: 56,
    name: "Binance Smart Chain",
    currency: "BNB",
    rpcUrl: "https://bsc-dataseed.binance.org/",
    explorerUrl: "https://bscscan.com/",
}
export const projectId = "42bebde03ec65d4beb88ef107eb10145";
export const defaultProviderUrls = [
    "https://bsc-dataseed.bnbchain.org",
    "https://bsc-dataseed1.bnbchain.org",
    "https://bsc-dataseed2.bnbchain.org",
    "https://bsc-dataseed3.bnbchain.org",
    "https://bsc-dataseed4.bnbchain.org"
];
export const fallbackRpcProvider = new ethers.FallbackProvider(
    defaultProviderUrls.map(url => new ethers.JsonRpcProvider(url, bsc.chainId, {staticNetwork: true}))
);
