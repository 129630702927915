import React, {createContext, useEffect, useState} from "react";
import {getAllLivePrices} from "../../data/data";
import {getAllLiveTokenFundingRates, getAllLiveTokens} from "../../data/blockchain";

const DataContext = createContext(undefined);
const DataDispatchContext = createContext(undefined);
const defaultData = {
    livePrices: {},
    liveTokens: {},
    fundingRates: null
};

function DataProvider({children}) {
    const LIVE_PRICES_FETCH_INTERVAL = 1000 * 10;
    const LIVE_TOKEN_FUNDING_RATES_FETCH_INTERVAL = 1000 * 15;
    const [data, setData] = useState(defaultData);

    useEffect(() => {
        const getAllLivePricesHandler = async () => {
            getAllLivePrices().then((data) => {
                setData(prevData => {
                    return {...prevData, livePrices: data.data.prices}
                });
            }).catch((error) => {
                console.log(error);
            });
        };

        const getAllLiveTokenFundingRatesHandler = async () => {
            getAllLiveTokenFundingRates(data.livePrices).then((data) => {
                setData(prevData => {
                    return {...prevData, fundingRates: data}
                });
            });
        };

        const livePricesTimer = setInterval(
            getAllLivePricesHandler,
            LIVE_PRICES_FETCH_INTERVAL
        );
        const liveTokenFundingRatesTimer = setInterval(
            getAllLiveTokenFundingRatesHandler,
            LIVE_TOKEN_FUNDING_RATES_FETCH_INTERVAL
        );

        getAllLivePricesHandler().then();
        getAllLiveTokenFundingRatesHandler().then();

        return () => {
            clearInterval(livePricesTimer);
            clearInterval(liveTokenFundingRatesTimer);
        }
    }, []);

    useEffect(() => {
        if (!data.fundingRates) {
            return;
        }

        getAllLiveTokens(data.livePrices, data.fundingRates).then((tokenData) => {
            setData(prevData => {
                return {...prevData, liveTokens: tokenData}
            });
        });
    }, [data.fundingRates]);

    return (
        <DataContext.Provider value={data}>
            <DataDispatchContext.Provider value={setData}>
                {children}
            </DataDispatchContext.Provider>
        </DataContext.Provider>
    );
}

export {DataProvider, DataContext, DataDispatchContext, defaultData};
