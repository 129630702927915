import {LineChart, Line, ResponsiveContainer} from 'recharts';
import "./Chart.css";

export function Chart(props) {
    return (
        <ResponsiveContainer width="100%" height="80%">
            <LineChart
                data={props.data}
            >
                <Line type="linear" dataKey="pv" stroke={props.style.lineColor} strokeWidth={3} dot={false} />
            </LineChart>
        </ResponsiveContainer>
    );
}
