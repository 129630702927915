import "./InfoBox.css";
import "../../assets/styles/colors.css";
import {Chart} from "../Chart/Chart";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowTrendDown, faArrowTrendUp, faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import logoWhite from "../../assets/logo-white-icon.png";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import {Link} from "react-router-dom";
import routes from "../../constants/routes";
import ExternalLinkButton from "../ExternalLinkButton/ExternalLinkButton";
import {Tooltip} from 'react-tooltip'


function CustomSkeleton(props) {
    return <Skeleton
        baseColor={"#0f121e"}
        highlightColor={"#171b2c"}
        width={props.width ? props.width : "100%"}
        height={props.height ? props.height : "100%"}
        containerClassName={props.containerClassName}
    />
}

function ValueWithLoader(props) {
    return (
        props.isLoading ? <CustomSkeleton
                containerClassName={props.containerClassName}
                width={props.width}
                height={props.height}/> :
            <span
                data-tooltip-id={props.hasTooltip ? props.tooltipId : null}
                data-tooltip-place={props.hasTooltip ? props.tooltipPlace : null}
            >
            {props.value}
            {props.hasTooltip ? <FontAwesomeIcon className={props.tooltipClass ? props.tooltipClass : "tooltip-icon-subcontent-info"} icon={faInfoCircle}/> : null}
        </span>
    )
}

function InfoBoxBasic(props) {
    return (
        <div className="infobox-basic-container">
            <div className="infobox-basic-title">{props.title}</div>
            <div className="infobox-basic-content">
                <ValueWithLoader value={props.content} isLoading={props.dataLoading?.content}/>
            </div>
            <div
                className={"infobox-basic-subcontent" + (props.hasSubcontentDirection ? (props.isSubcontentPositive ? " positive" : " negative") : " neutral")}>
                <ValueWithLoader value={props.subcontent} isLoading={props.dataLoading?.subcontent}/>
                {props.hasTooltip && !props.dataLoading?.subcontent &&
                    <span className={"tooltip-icon-subcontent-info"}
                          data-tooltip-id={props.hasTooltip ? "item-tooltip-" + props.title : null}>
                    <FontAwesomeIcon icon={faInfoCircle}/>
                </span>
                }
                {props.hasTooltip &&
                    <Tooltip
                        id={"item-tooltip-" + props.title}
                        className="infobox-tooltip-subcontent-info"
                    >
                        <div className={"infobox-tooltip-subcontent-wrapper"}>
                            <span className={"infobox-tooltip-subcontent-main-text"}>{props.tooltipMainContent}</span>
                            <span
                                className={"infobox-tooltip-subcontent-secondary-text"}>{props.tooltipSecondaryContent}</span>
                        </div>
                    </Tooltip>
                }
            </div>
        </div>
    );
}

function InfoBoxTradingActivity(props) {
    return (
        <div className="infobox-trading-activity-container">
            <div className="infobox-trading-activity-details-container">
                <div className="infobox-trading-activity-title">{props.title}</div>
                <div className="infobox-trading-activity-details">
                    <div className="infobox-trading-activity-detail">
                        <span className="infobox-trading-activity-detail-name">PNL&ROE (Live): </span>
                        <span className="infobox-trading-activity-detail-value">
                            <ValueWithLoader value={props.activity.pnlRoe24h}
                                             isLoading={props.dataLoading?.pnlRoe24h}
                            />
                        </span>
                        <span
                            className={"infobox-trading-activity-detail-subvalue" + (props.activity.isPnlRoe24hDirectionPositive ? " positive" : " negative")}>{props.activity.pnlRoe24hPercentage}</span>
                    </div>
                    <div className="infobox-trading-activity-detail">
                        <span className="infobox-trading-activity-detail-name">Generated Volume: </span>
                        <span className="infobox-trading-activity-detail-value">
                            <ValueWithLoader value={props.activity.generatedVolume}
                                             isLoading={props.dataLoading?.generatedVolume}
                            />
                        </span>
                    </div>
                    <div className="infobox-trading-activity-detail">
                        <span className="infobox-trading-activity-detail-name">Accrued Points: </span>
                        <span
                            className="infobox-trading-activity-detail-value positive-blue">
                            <ValueWithLoader value={props.activity.accruedPoints}
                                             isLoading={props.dataLoading?.accruedPoints}
                            />
                        </span>
                    </div>
                    <div className="infobox-trading-activity-detail">
                        <span className="infobox-trading-activity-detail-name">Wins / Losses: </span>
                        <span
                            className="infobox-trading-activity-detail-value">
                            <ValueWithLoader value={props.activity.winsAndLosses}
                                             isLoading={props.dataLoading?.winsAndLosses}
                            />
                        </span>
                    </div>
                </div>
                <div className="infobox-trading-activity-trade-button-container">
                    <ExternalLinkButton link={routes.PERPETUAL.path} name="Trade" newTab={routes.PERPETUAL.newTab}
                                        className="infobox-trading-activity-trade-button"/>
                </div>
            </div>
            <div className="infobox-trading-activity-statistics-box">
                <div className="infobox-trading-activity-statistics-inner-container">
                    <div className="infobox-trading-activity-pnl-details">
                        <span className="infobox-trading-pnl-name">Statistics:</span>
                        <span className="infobox-trading-pnl-title">All Time PNL&ROE</span>
                        <span className="infobox-trading-pnl-value">{props.activity.pnlRoeValue}</span>
                        <div className="infobox-trading-pnl-row"><span
                            className={"infobox-trading-pnl-change" + (props.activity.pnlRoeChangePositive ? " positive" : " negative")}>{props.activity.pnlRoeChange}</span>
                            {props.activity.pnlRoeChangePositive &&
                                <span className="positive"><FontAwesomeIcon icon={faArrowTrendUp}/></span>}
                            {!props.activity.pnlRoeChangePositive &&
                                <span className="negative"><FontAwesomeIcon icon={faArrowTrendDown}/></span>}
                        </div>
                    </div>
                    <div className="infobox-trading-activity-chart">
                        <Chart data={props.activity.tradingData}
                               style={{lineColor: "#2D5BFF"}}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

function InfoBoxSwychStatistics(props) {
    return (
        <div className="infobox-swych-statistics-container">
            <div className="infobox-swych-statistics-inner-container">
                <div className="infobox-swych-statistics-details-container">
                    <div className="infobox-swych-statistics-token-header">
                        <span className="infobox-swych-statistics-token-label">Swych Token Statistics:</span>
                        <div>
                            <span
                                className={"infobox-swych-statistics-token-value" + (props.data.swychTokenPriceChangePositive ? " positive" : " negative")}>
                                {props.data.swychTokenPriceChange}
                            </span>
                            {props.data.swychTokenPriceChangePositive && <span
                                className="infobox-swych-statistics-token-value-trend-arrow positive"><FontAwesomeIcon
                                icon={faArrowTrendUp}/></span>}
                            {!props.data.swychTokenPriceChangePositive && <span
                                className="infobox-swych-statistics-token-value-trend-arrow negative"><FontAwesomeIcon
                                icon={faArrowTrendDown}/></span>}
                        </div>
                    </div>
                    <div className="infobox-swych-statistics-token-body">
                        <div className="infobox-swych-statistics-holdings">
                            <div className="infobox-swych-statistics-holdings-data">
                                <div className="infobox-swych-statistics-holdings-label">Your Holdings:</div>
                                <div className="infobox-swych-statistics-holdings-value">
                                    <img src={logoWhite} alt="Swych Logo"
                                         className="infobox-swych-statistics-holdings-logo"/><span>{props.data.swychHoldings}</span>
                                </div>
                                <div
                                    className="infobox-swych-statistics-holdings-value-usd">~ {props.data.swychHoldingsInUsd}</div>
                            </div>
                            <div className="infobox-swych-statistics-holdings-buy-sell">
                                <Link to={"https://app.swych.finance/"} target={"_blank"}><span
                                    className="infobox-swych-statistics-holdings-buy-sell-label">Buy / Sell</span></Link>
                            </div>
                        </div>
                        <div className="info-swych-statistics-chart"><Chart data={props.data.swychPriceData}
                                                                            style={{lineColor: "#FFD481"}}/></div>
                    </div>
                    <div className="infobox-swych-statistics-token-footer">
                        <div className="infobox-swych-statistics-token-footer-label">Price:</div>
                        <div className="infobox-swych-statistics-token-footer-value">{props.data.swychTokenPrice}</div>
                        <div className="infobox-swych-statistics-token-footer-label">Volume:</div>
                        <div className="infobox-swych-statistics-token-footer-value">{props.data.swychTokenVolume}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function InfoBoxStats(props) {
    const isClosed = props.closed;

    return (
        <>
            {
                isClosed &&
                <div className="infobox-stats-container-closed">
                    <span className="infobox-stats-placeholder">Coming Soon...</span>
                </div>
            }
            {
                !isClosed &&
                <div className="infobox-stats-container">
                    <div className="infobox-stats-inner-container">
                        <div className="infobox-stats-title">{props.title}</div>
                        <div className="infobox-stats-items-container">
                            {
                                props.data.map(
                                    (item, index) => {
                                        return (
                                            <div className="infobox-stats-item" key={index}>
                                                <span className="infobox-stats-item-name">{item.name}:</span>
                                                <div className="infobox-stats-item-value">
                                                    <ValueWithLoader
                                                        value={item.value}
                                                        isLoading={item.valueLoading}
                                                        width={"5em"}
                                                        hasTooltip={item.hasTooltip}
                                                        tooltipId={"item-tooltip-" + item.name + index}
                                                        tooltipPlace={item.tooltipPlace ? item.tooltipPlace : "top"}
                                                    />
                                                    {item.hasTooltip &&
                                                        <Tooltip
                                                            id={"item-tooltip-" + item.name + index}
                                                            className="infobox-stats-tooltip"
                                                        >

                                                            <h3 className="infobox-stats-tooltip-title">{item.tooltipTitle}</h3>
                                                            <div>{item.tooltipContent}</div>

                                                        </Tooltip>
                                                    }
                                                </div>
                                            </div>
                                        );
                                    }
                                )
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export {InfoBoxBasic, InfoBoxTradingActivity, InfoBoxSwychStatistics, InfoBoxStats};
