import "./Layout.css";
import React from "react";
import Header from "../Header/Header";
import Content from "../Content/Content";

function Layout({ children }) {
  return (
    <div className="wrapper">
      <Header />
      <Content children={children} />
    </div>
  );
}

export default Layout;
