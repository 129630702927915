import {PoolAbi, OracleAbi, LoyaltyTokenAbi, SwychAbi, PoolLensAbi} from "../abis/abis";
import {ethers} from "ethers";
import {fallbackRpcProvider} from "./web3";

const contractAddresses = {
    Pool: "0xF86f70fb4959a9FCF1e7dD67A05dC0AC95c3802d",
    Oracle: "0xe4460109425EbC1CE34cAd59Ab7ce60535956BF5",
    LoyaltyToken: "0xd6624102503252Cb06C1fBb4d3b1AA6eE9A072ca",
    Swych: "0x9334e37faD7c41Cd6C9565Bff3A97CE31CEE52a3",
    PoolLens: "0x0Ae7872e070673144d4AEe36ee6B0Ef997C30cD7",
};

const Pool = new ethers.Contract(contractAddresses.Pool, PoolAbi, fallbackRpcProvider);
const Oracle = new ethers.Contract(contractAddresses.Oracle, OracleAbi, fallbackRpcProvider);
const LoyaltyToken = new ethers.Contract(contractAddresses.LoyaltyToken, LoyaltyTokenAbi, fallbackRpcProvider);
const Swych = new ethers.Contract(contractAddresses.Swych, SwychAbi, fallbackRpcProvider);
const PoolLens = new ethers.Contract(contractAddresses.PoolLens, PoolLensAbi, fallbackRpcProvider);

export {
    Pool,
    Oracle,
    LoyaltyToken,
    Swych,
    PoolLens,
    contractAddresses,
};
